
import EmblaCarousel from 'embla-carousel';
import ClassNames from 'embla-carousel-class-names'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

export default {
    data() {
        return {
            isSharePopupVisible: false,
            carouselMode: true,
            disablePrevBtn: true,
        };
    },
    props: {
        isScreenViewOpen: {
            type: Boolean,
            default: true,
            required: true,
        },
        closeScreenViewPopup: {
            type: Function,
            required: true,
        },
        imageListProp: {
            type: Array,
            default: [],
            required: true,
        },
        currentScreenViewImageIndex: {
            type: Number,
            default: 0,
        },
        navigateToPreviousImage: {
            type: Function,
            required: true,
        },
        navigateToNextImage: {
            type: Function,
            required: true,
        },
        handleKeypress: {
            type: Function,
            required: false,
        },
        selectedPlatform: {
            type: String,
            required: true,
        },
        getNextSet: {
            type: Function,
            required: true,
        },
        showShimmer: {
            type: Boolean,
            required: true,
            default: false,
        },
        appIcon: {
            type: String,
            required: true,
        },
        appName: {
            type: String,
            required: true,
        },
        flowTitle: {
            type: String,
            required: true,
        },
        appSlug: {
            type: String,
            required: true,
        },
        screensData: {
            type: Array,
            required: true,
        },
    },
    computed: {
        isSelectedPlatformWeb() {
            return this.selectedPlatform === "web";
        },
        isScreenViewVisible() {
            if (!this.imageListProp) {
                return false;
            }
            if (this.imageListProp.length < 1) {
                return false;
            }
            return this.isScreenViewOpen;
        },
        getImageURL() {
            if (!this.imageListProp) {
                return "";
            }
            if (this.imageListProp.length < 1) {
                return "";
            }
            let imageURL =
                this.imageListProp[this.currentScreenViewImageIndex].img_url;
            return imageURL;
        },
        getApplicationURL() {
            return window.location.href;
        },
        getSecondPrevImage() {
            return this.imageListProp[this.currentScreenViewImageIndex - 2].img_url;
        },
        getFirstPrevImage() {
            return this.imageListProp[this.currentScreenViewImageIndex - 1].img_url;
        },
        getFirstNextImage() {
            return this.imageListProp[this.currentScreenViewImageIndex + 1].img_url;
        },
        getSecondNextImage() {
            return this.imageListProp[this.currentScreenViewImageIndex + 2].img_url;
        },
    },
    methods: {
        handleLinkClick() {
            this.$store.commit('setShowSearchPopup', false);
            this.$store.dispatch('enableScroll');
        },
        copyToFigma() {
            // console.log('copy to figma')
        },
        handleKeypressNew(event) {
            if (event.key === 'ArrowRight') {
                event.preventDefault();
                this.mobileNextNav();
            } else if (event.key === 'ArrowLeft') {
                event.preventDefault();
                this.mobilePrevNav();
            } else if (event.key === 'Escape' || event.key === 'C' || event.key === 'c') {
                this.closeScreenViewPopup()
            } else {
                // console.log(event.key);
            }
        },
        getNextSetImages() {
            const activeIndex = this.embla.selectedScrollSnap();
            if (activeIndex === this.imageListProp.length - 1) {
                this.getNextSet()
            }
        },
        carouselPreviousFunc() {
            this.carouselPrev()
        },
        carouselNextFunc() {
            this.carouselNext()
            this.getNextSetImages()
        },
        mobilePrevNav() {
            this.carouselMode ? this.carouselPreviousFunc() : this.navigateToPreviousImage()
        },
        mobileNextNav() {
            this.carouselMode ? this.carouselNextFunc() : this.navigateToNextImage()
            this.disablePrevBtn = false
        },
        carouselNext() {
            this.embla.scrollNext();
        },
        carouselPrev() {
            this.embla.scrollPrev();
        },
        initScreenCarousel() {
            this.embla = EmblaCarousel(this.$refs.flowsScreenCarousel, {
                dragFree: true, startIndex: 0, loop: false,
            }, [ClassNames(), WheelGesturesPlugin({ forceWheelAxis: 'x' })]);
            this.$refs.flowsScreenCarousel.focus();
            this.embla.on('select', this.logSlidesInView);
            this.embla.on('pointerUp', this.logSlidesInView);
            // Move smoothly to the startIndex after initializing the carousel
            this.$nextTick(() => {
                this.embla.scrollTo(this.currentScreenViewImageIndex, {
                    duration: 1000, // Duration in milliseconds
                    easing: (t) => t * (2 - t) // Ease-out function
                });
            });
        },
        logSlidesInView() {
            const slidesInView = this.embla.slidesInView(true);
            const lastItemIndex = this.imageListProp.length - 1;
            if (slidesInView.indexOf(lastItemIndex) !== -1) {
                // Last item in view, do something
                this.getNextSet()
            }
        },
        toggleCarouselMode() {
            this.carouselMode = !this.carouselMode;
        },
        onClickTopContainer() {
            if (this.isSharePopupVisible) {
                this.closeSharePopupView();
                return;
            }

            this.closeScreenViewPopup();
        },
        toggleSharePopupView() {
            this.isSharePopupVisible = !this.isSharePopupVisible;
        },
        closeSharePopupView() {
            this.isSharePopupVisible = false;
        },
        shareToTwitter() {
            let shareText = encodeURIComponent("");
            let urlToShare = window.location.href;
            let hashtag1 = "AppShotsDesign";
            let hashtag2 = "designs";
            let twitterShareUrl = `http://twitter.com/share?text=${shareText}&url=${urlToShare}&hashtags=${hashtag1},${hashtag2}`;
            window.open(twitterShareUrl, "_blank").focus();
        },
        shareToFacebook() {
            let urlToShare = window.location.href;
            let facebookShareUrl = `http://www.facebook.com/share.php?u=${urlToShare}`;
            window.open(facebookShareUrl, "_blank").focus();
        },
        shareOnEmail() {
            let shareText = encodeURIComponent("");
            let urlToShare = window.location.href;
            let facebookShareUrl = `mailto:?Subject=${shareText}&body=${urlToShare}`;
            window.open(facebookShareUrl, "_blank").focus();
        },
    },
    watch: {
        isScreenViewOpen: function (value) {
            const context = this
            if (value) {
                this.$store.dispatch('disableScroll');
                setTimeout(function () {
                    context.initScreenCarousel();
                }, 1000)
            } else {
                this.$store.dispatch('enableScroll');
            }
        },
    },
};
