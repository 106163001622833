

import EmblaCarousel from 'embla-carousel';
import ClassNames from 'embla-carousel-class-names'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import DownloadFlows from './DownloadFlows.vue';

export default {
  name: 'appFlowAppShotComponent',
  components: { DownloadFlows },
  props: {
    appShot: Object,
    appShotScreens: Array,
    flow: Object,
  },
  data() {
    return {
      showPrevButton: false,
      showNextButton: false,
      currentIndex: 0,
      isScreenViewOpen: false,
      currentImageIndex: 0,
      isLoading: false,
      zipStatus: '',
      currentPlatform: this.$store.state.selectedPlatform
    };
  },
  // watch: {
  //   appShot: {
  //     handler: function (appShotItem) {
  //       if (appShotItem.screens && appShotItem.screens.length > 3) {
  //         const context = this
  //         setTimeout(function () {
  //           context.initFlowDetailSlider(); // Ensure 'this' points to the Vue instance
  //         }, 2000)
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  computed: {
    platformName() {
      const platform = this.$store.state.selectedPlatform;
      if (platform === 'android' || platform === 'web') {
        return platform.charAt(0).toUpperCase() + platform.slice(1);
      } else if (platform === 'ios') {
        return 'iOS';
      } else {
        return platform; // Return the original platform name if it's none of the above
      }
    },
    currentUser() {
      return this.$store.state.globalUserData;
    },
    isWebAppShots() {
      return this.$store.state.selectedPlatform === 'web'
    },
    getCurrentScreenViewIndex() {
      return this.currentImageIndex
    },
  },
  methods: {
    showLoginPopup() {
      this.$router.push({ query: { redirection_url: encodeURIComponent(window.location.href) } });
      this.$store.commit('showLoginPopup')
      document.body.classList.add('popup-visible')
    },
    getNextScreens() {
      // console.log('get next set')
    },
    navigateToPrevious() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      }
    },
    navigateToNext() {
      if (this.currentImageIndex < this.appShotScreens.length - 1) {
        this.currentImageIndex++;
        return
      }
    },
    openScreenViewPopup(selectedIndex) {
      if (!this.isAuthenticated()) {
        this.showToaster('Please login to continue', 'error')
        this.$store.commit('setLoginPopupTitle', 'Join Appshots')
        this.showLoginPopup();
        return
      }
      this.currentImageIndex = selectedIndex
      this.isScreenViewOpen = true
    },
    closeScreenViewPopup() {
      this.currentImageIndex = 0
      this.isScreenViewOpen = false
    },
    // imageLoaded() {
    //   this.$store.state.isImagesLoading = false;
    // },
    openApp(slug) {
      const link = `/apps/${slug}/`
      this.$router.push(link)
    },
    initFlowDetailSlider() {
      this.embla = EmblaCarousel(this.$refs.flowDetailsSlider, {
        dragFree: true,
        watchDrag: (event) => {
          const currentIndex = this.embla.selectedScrollSnap();
          this.currentIndex = currentIndex;
          this.additionalFunction(event, currentIndex);
          return true
        }
      }, [ClassNames(), WheelGesturesPlugin({ forceWheelAxis: 'x' })]);
      this.showNextButton = true
    },
    checkSlideInView(index) {
      const slidesInView = this.embla.slidesInView();
      if (slidesInView.includes(index)) {
        this.showNextButton = false
      } else {
        this.showNextButton = true
      }
    },
    additionalFunction(event, currentIndex) {
      if (currentIndex === 0) {
        this.showPrevButton = false
      } else {
        this.showPrevButton = true
      }
      this.checkSlideInView(this.appShotScreens.length - 1)
    },
    moveNext() {
      if (this.embla) {
        if (this.currentIndex === 0) {
          this.showPrevButton = false
        } else {
          this.showPrevButton = true
        }
        if (this.currentIndex === this.appShotScreens.length - 1) {
          this.showNextButton = false
        } else {
          this.showNextButton = true
        }

        if (this.currentIndex < this.appShotScreens.length - 1) {
          this.currentIndex++;
        }
        this.embla.scrollNext();
      }
    },
    movePrev() {
      if (this.embla) {
        if (this.currentIndex === 0) {
          this.showPrevButton = false
        } else {
          this.showPrevButton = true
        }
        if (this.currentIndex > 0) {
          this.currentIndex--;

        }
        this.embla.scrollPrev();
      }
    },
  },
};
